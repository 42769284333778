/* Header1 */
.header1 {
  position: fixed;
  top: 0;
  background-color: #ffffff;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;
}

.header1 > div {
  max-width: 1280px; /* matches Tailwind's container width */
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem; /* 1rem = 16px, matching Tailwind's px-4 */
}

/* Header2 */
.header2 {
  position: fixed;
  top: 80px;
  background-color: #000000;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  z-index: 9;
}

.header2 nav {
  max-width: 1280px; /* matches Tailwind's container width */
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem; /* 1rem = 16px, matching Tailwind's px-4 */
}

/* Update the logo container styles */
.header2 nav > div:first-child {
  padding: 0; /* Remove the py-1 padding */
}

.header2 img {
  height: 48px; /* Adjust height as needed */
}

/* Media query for responsive adjustments */
@media (max-width: 768px) {
  .header1, .header2 {
    padding: 0 0.5rem;
  }
  
  .header1 > div,
  .header2 nav {
    padding: 0 0.5rem;
  }
}