/* About Us Section Styling */
.about-us {
    align-items: center;
    padding: 60px 20px;
    background-color:  #000000;
}


.about-title {
    text-align: center; 
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 20px;
}

.about-description {
    font-size: 1rem;
    color: #ffffff;
    line-height: 1.5;
    text-align: center; 
    margin: 0;
    max-width: 900px;
}


.row {
    display: flex;
    justify-content: center; 
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1.5rem; 
}

.small-section {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    text-align: center;
}

.text {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: #ffffff;
    text-align: center;
}

.row1 {
    display: flex;
    justify-content: center; 
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem; 
    margin-top: 40px;
}