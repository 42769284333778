/* Default Styles for Mobile (Phone) */
.landing-page {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.landing-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.overlay {
  position: absolute; /* Ensures it stays positioned inside its parent */
  top: 50%; /* Moves the element's top edge to 50% of the parent */
  left: 50%; /* Moves the element's left edge to 50% of the parent */
  transform: translate(-50%, -50%); /* Centers the element exactly */
  color: white; /* Adjust text color */
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem; /* Default font size */
  padding: 10px;
}

.merriweather-light {
  font-family: "Merriweather", serif;
  font-weight: 200;
  font-style: normal;
  font-size: medium;
}

.merriweather-regular {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}

.merriweather-bold {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
  font-size: xx-large;
}


/* For tablets, phones, and small laptops (min-width: 481px to 1024px) */
@media (min-width: 481px) and (max-width: 1024px) {
  .overlay h1 {
      font-size: 2rem; /* Adjust heading size */
  }

  .overlay p {
      font-size: 1.2rem; /* Adjust paragraph size */
  }
}

/* For laptops and larger tablets (min-width: 1025px to 1366px) */
@media (min-width: 1025px) and (max-width: 1366px) {
  .overlay h1 {
      font-size: 2.5rem; /* Increase heading size */
  }

  .overlay p {
      font-size: 1.5rem; /* Increase paragraph size */
  }
}

/* For desktop screens (min-width: 1367px and above) */
@media (min-width: 1367px) {
  .overlay h1 {
      font-size: 3rem; /* Larger heading for desktop */
  }

  .overlay p {
      font-size: 1.75rem; /* Larger paragraph for desktop */
  }
}
