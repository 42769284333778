/* Services Section Styling */
.services {
    padding: 60px 20px;
    background-color: #f4f4f4;
}

.services-title {
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 40px;
    margin-top: 40px;
    text-align: center;
    color: #000000;
}


.service h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

.service p {
    font-size: 1rem;
    color: #666;
}

/* Add border to divide sections */
.service:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border-right: 1px solid #ccc;
}
